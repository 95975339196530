















































import './scss/TheAuth.scss';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { IAuth, IUser } from '@store/modules/user/Interfaces';
import { userNamespace } from '@store/modules/user';
import { UserActionTypes } from '@store/modules/user/Types';
import { isEmail, isPassword } from '@utility/rules.js';
import { makeFocusInput } from '@utility/makeFocusInput';

@Component({
    name: 'Auth',

    components: {
        BaseAuth: () => import('@pages/the-auth/TheBaseAuth.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
    },
})
export default class Auth extends Vue {
    $refs!: {
        authForm: HTMLElement,
    }

    @userNamespace.State('userInfo') user!: IUser;
    @userNamespace.Action(UserActionTypes.A_LOGIN) login!: (payload) => Promise<IUser>
    @userNamespace.Action(UserActionTypes.A_AUTH_USER) authUser!: (auth: boolean) => Promise<void>

    auth: IAuth = { login: '', password: '' };
    error: string = '';
    errorsText: {[key: string]: string} = {
        empty: 'Не заполнено одно или несколько обязательных полей',
        emailFormat: 'Неверный формат E-mail',
        passwordFormat: 'Пароль должен содержать минимум 4 символа',
    }

    mounted() {
        makeFocusInput(this.$refs.authForm);
    }

    validateForm() {
        const email = this.auth.login;
        const { password } = this.auth;
        if (!email || !password) {
            this.error = this.errorsText.empty;
        } else {
            this.error = '';
        }
        if (!this.error && email) {
            this.error = isEmail(email) ? '' : this.errorsText.emailFormat;
        }
        if (!this.error && password) {
            this.error = isPassword(password) ? '' : this.errorsText.passwordFormat;
        }
        if (!this.error) {
            this.dispatchLogin();
        }
    }

    dispatchLogin() {
        this.login({ auth: this.auth, appId: this.$route.params.projectId })
        .then(() => {
            this.authUser(true);
            this.$router.push({ path: '/projects' });
        })
        .catch(() => {
            this.error = 'Неправильный логин или пароль';
        })
    }
}
